@keyframes highlightRow {
  0% {
    background-color: #f59e0b;
  }

  100% {
    background-color: initial;
  }
}

.highlight {
  animation: highlightRow 3s;
}
